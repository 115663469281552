import { BaseIcon, Flex } from '@cof/plastic-components';
import React from 'react';
import styled, { css } from 'styled-components';

const mobileCircleSvg = () => (
    <svg
        width="258"
        height="254"
        viewBox="0 0 258 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="240" cy="240" r="240" fill="#FFE433" />
        <g clipPath="url(#clip0_1103_3323)">
            <path
                d="M239.628 106.604C221.008 106.053 196.814 95.885 178.378 84.125C159.145 96.13 134.951 106.604 117.373 106.604C117.373 106.604 111.248 194.191 178.378 218.875C245.691 194.191 239.628 106.604 239.628 106.604ZM201.836 176H157.92V163.75C157.92 163.75 158.9 163.382 159.145 163.137C160.431 162.219 161.044 161.116 161.595 159.769C162.146 158.421 162.391 156.829 162.391 154.991V151.5H155.164V139.25H162.453V133.615C162.453 127.674 164.229 123.019 167.72 119.711C171.211 116.404 176.356 114.75 182.298 114.75C186.891 114.75 191.363 115.791 194.425 118.119C197.488 120.446 199.693 123.631 201.101 127.796C201.163 127.98 201.469 129.266 201.469 129.266L190.138 131.41C190.138 131.41 189.954 130.675 189.709 130.062C189.158 128.531 188.116 127 187.014 126.265C185.911 125.53 183.951 125.224 182.42 125.224C180.093 125.224 177.888 125.897 176.54 127.245C175.193 128.592 174.58 130.614 174.58 133.37V139.25H191.853V151.5H174.58V154.991C174.58 158.237 174.213 160.932 172.13 163.137C171.824 163.444 171.518 163.75 171.518 163.75C171.518 163.75 172.191 163.75 172.436 163.75H201.836V176Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1103_3323">
                <rect
                    width="147"
                    height="147"
                    fill="white"
                    transform="translate(105 78)"
                />
            </clipPath>
        </defs>
    </svg>
);

const desktopCircleSvg = () => (
    <svg
        width="528"
        height="508"
        viewBox="0 0 528 508"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <circle cx="368.5" cy="365.348" r="364.5" fill="#FFE433" />
        </g>
        <g clipPath="url(#clip0_1102_3049)">
            <path
                d="M449.255 199.208C412.015 198.105 363.627 177.77 326.755 154.25C288.29 178.26 239.902 199.208 204.745 199.208C204.745 199.208 192.495 374.382 326.755 423.75C461.382 374.382 449.255 199.208 449.255 199.208ZM373.672 338H285.84V313.5C285.84 313.5 287.8 312.765 288.29 312.275C290.862 310.437 292.087 308.233 293.19 305.538C294.292 302.843 294.782 299.657 294.782 295.982V289H280.327V264.5H294.905V253.23C294.905 241.347 298.457 232.037 305.44 225.422C312.422 218.807 322.712 215.5 334.595 215.5C343.782 215.5 352.725 217.583 358.85 222.238C364.975 226.893 369.385 233.263 372.202 241.593C372.325 241.96 372.937 244.532 372.937 244.532L350.275 248.82C350.275 248.82 349.907 247.35 349.417 246.125C348.315 243.062 346.232 240 344.027 238.53C341.822 237.06 337.902 236.448 334.84 236.448C330.185 236.448 325.775 237.795 323.08 240.49C320.385 243.185 319.16 247.227 319.16 252.74V264.5H353.705V289H319.16V295.982C319.16 302.475 318.425 307.865 314.26 312.275C313.647 312.887 313.035 313.5 313.035 313.5C313.035 313.5 314.382 313.5 314.872 313.5H373.672V338Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1102_3049">
                <rect
                    width="294"
                    height="294"
                    fill="white"
                    transform="translate(180 142)"
                />
            </clipPath>
        </defs>
    </svg>
);

const MobileIcon = styled(BaseIcon)`
    ${({ theme: { breakpoints } }) => css`
        ${breakpoints.mq.tabletMin} {
            display: none;
        }
    `}
`;

const DesktopIcon = styled(BaseIcon)`
    ${({ theme: { breakpoints } }) => css`
        ${breakpoints.mq.mobileMax} {
            display: none;
        }
    `}
`;

const LogoCircle = props => (
    <Flex
        width={[1, null, 1 / 2]}
        alignItems="end"
        justifyContent="end"
        data-qa-id="logo-circle"
        {...props}
    >
        <MobileIcon IconSvg={mobileCircleSvg} width="68%" height="auto" />
        <DesktopIcon
            IconSvg={desktopCircleSvg}
            width="75%"
            maxWidth="60rem"
            height="auto"
        />
    </Flex>
);
export default LogoCircle;
