import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import {
    ApplicationLayout,
    Box,
    Button,
    capitalOneTheme,
    CapitalOneLogoPrimaryLight,
    ChatBubbleIcon,
    Flex,
    globalStyles,
    Header,
    Heading,
    Link,
    Paragraph,
    styleReset,
    PageRowFullHeight
} from '@cof/plastic-components';
import '@cof/plastic-components/fonts.css';
import LogoCircle from './components/LogoCircle';

const GlobalStyle = createGlobalStyle`
${styleReset}
${globalStyles}
`;

const CapitalOneLogo = () => (
    <Flex bg="primary1" p={['xs', null, 'sm']}>
        <Box width={[1, null, 1 / 2]}>
            <Header bg="primary1" data-qa-id="landing-page-logo">
                <CapitalOneLogoPrimaryLight />
            </Header>
        </Box>
    </Flex>
);

const Introductions = () => (
    <>
        <Heading
            color="shade1"
            level="1"
            textAlign="left"
            data-qa-id="landing-page-heading"
        >
            Open Banking Developer Portal
        </Heading>
        <Paragraph
            py="sm"
            size="large"
            variant="light"
            data-qa-id="landing-page-paragraph"
        >
            Welcome to Capital One&apos;s Open Banking Developer Portal. A place
            to find our Open Banking APIs offering account access as mandated
            under PSD2.
        </Paragraph>
    </>
);

const ViewDocumentationButton = () => (
    <Button
        my="md"
        data-qa-id="landing-page-get-started-button"
        as="a"
        href="/docs/index.html"
        maxWidth={2 / 3}
    >
        View documentation
    </Button>
);

const TalkToUs = () => {
    const StyledLink = styled(Link)`
        overflow-wrap: anywhere;
    `;

    return (
        <Flex mb={['sm', null, 'md']}>
            <Box mr="lg">
                <ChatBubbleIcon
                    color="shade1"
                    size="29px"
                    data-qa-id="landing-page-chat-icon"
                />
            </Box>
            <Paragraph
                data-qa-id="landing-page-card-text"
                variant="light"
                size="medium"
                lineHeight={['1.4', null, '1.4']}
            >
                We hope our docs answer all your questions, but if you do spot
                something that needs a fix, are having any problems or just need
                to get in touch send an email to{' '}
                <StyledLink
                    data-qa-id="landing-page-email-link"
                    href="mailto:ukdevelopersupport@capitalone.com"
                    variant="light"
                >
                    ukdevelopersupport@capitalone.com
                </StyledLink>
                .
            </Paragraph>
        </Flex>
    );
};

function App() {
    return (
        <div className="App" style={{ lineHeight: 0 }}>
            <ThemeProvider theme={capitalOneTheme}>
                <GlobalStyle />
                <ApplicationLayout Header={CapitalOneLogo}>
                    <Flex
                        alignContent="stretch"
                        bg="primary1"
                        flexWrap="wrap"
                        minHeight={[null, null, '71vh']}
                    >
                        <Box
                            p={['xs', null, 'sm']}
                            pr={[null, null, 'xl']}
                            width={[1, null, 1 / 2]}
                        >
                            <PageRowFullHeight>
                                <Introductions />
                                <ViewDocumentationButton />
                                <TalkToUs />
                            </PageRowFullHeight>
                        </Box>
                        <LogoCircle data-qa-id="landing-page-logo-circle" />
                    </Flex>
                </ApplicationLayout>
            </ThemeProvider>
        </div>
    );
}

export default App;
